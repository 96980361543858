import React, { Component } from "react";
import { connect } from "react-redux";
import { addDeliveryPriced, addPositionNewOrder, changeCountAdditives, changeCountPosition, changeModificationOrderPositionList, changeSale, changeUnit, clearNewOrder, deleteAdditivesNewOrder, deletePositionNewOrder } from "../../action/neworder";
import AdditivesWOKNewOrder from "./AdditivesWOKNewOrder";
import SaveNewOrderButton from "./SaveNewOrderButton";
class OrderPositionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputSale: '',
            openInputSale: false,
            openAdditivesWOK: false,
            openSaleDropDown: false,
            openUnitDropDown: false,
            openDescription: false,
            openDeliveryPriced: false
        };
        this.closeAdditivesWOK = this.closeAdditivesWOK.bind(this);
      }
    
    closeAdditivesWOK(){
        this.setState({openAdditivesWOK: false})
    }
    handleChangeInputSale(e){
        if(e.target.value <= 999999 && e.target.value > 0)
            return this.setState({inputSale: parseInt(e.target.value)})
        if(e.target.value > 999999)
            return this.setState({inputSale: 999999})
        if(e.target.value <= 0)
            return this.setState({inputSale: ''})
    };
    render() {
        return(
            <>
             <div className="position_list_row head">
                    <span className="position_list_position head_name"> Наименование </span>
                    <span className="position_list_position count"> Кол-во </span>
                    <span className="position_list_position price"> Цена </span>
                    <span className="position_list_position totalPrice"> Итого </span>
                </div>
                <div className="order_position_list_body">
                    {
                        this.props.addedgoods && this.props.addedgoods.map((item) => 
                            <>
                                <div key={item.index} className="position_list_column">
                                    <div className="position_list_row">
                                        <div className="position_list_position container_name"> 
                                            <div className="position_list_position row_name">
                                                {item.category.id === this.props.settings.mainWok &&
                                                <button
                                                className="order_position_list_inf_button"
                                                style={{ width: '1.5rem', height: '1.5rem', fontSize: '1.5rem'}}
                                                onClick={(() => this.setState({openAdditivesWOK: item.index}))}
                                                onBlur={this.closeAdditivesWOK}
                                                >
                                                    +
                                                </button>
                                                }
                                                <span className="position_list_position name">{`${!item.wokData ? item.name : (item.wokData.main ? item.wokData.main.title : 'No Title')} ${item.isMod ? item.isMod.title : ''}`}</span>
                                                {!item.wokData && item.description && item.description !== '-' && 
                                                <button 
                                                className="order_position_list_inf_button"
                                                onClick={(() => this.setState({openDescription : item.index}))}
                                                onBlur={(() => this.setState({openDescription : false}))}
                                                >
                                                    i
                                                </button>
                                                }                                        
                                            </div>                                        
                                            {item.category.id === this.props.settings.pizza && 
                                            <div className="position_list_position row_name" style={{marginTop: '5px'}}>                                    
                                                <div className="neworder_pizza_modification_button_div">
                                                    {item.modifications.map((mod) => 
                                                        <button 
                                                        className={`neworder_pizza_modification_button ${item.isMod.id === mod.id ? 'actived' : ''}`} 
                                                        onClick={(() => this.props.changeModificationOrderPositionList(item.index, mod))}> 
                                                        {mod.title}
                                                        </button>
                                                    )}
                                                </div>                                        
                                            </div>
                                            }                                    
                                        </div>
                                        <span className="position_list_position count"> 
                                            <button 
                                            className="position_list_count_button"
                                            onClick={(() => {
                                                if(!item.wokData)
                                                    return this.props.changeCountPosition(item.index, item.count, 'minus')
                                                return this.props.changeCountAdditives(item.index, item.count, 'minus',  item.wokData.main.id, 'main')
                                            })}
                                            >
                                                -
                                            </button> 
                                            <input
                                            className="new_order_input count"
                                            type="number"
                                            value={item.count}
                                            onChange={((e) => {
                                                if(!item.wokData)
                                                    return this.props.changeCountPosition(item.index, item.count, 'handle', e.target.value)
                                                return this.props.changeCountAdditives(item.index, item.count , 'handle',  item.wokData.main.id, 'main', e.target.value)
                                            })}
                                            onBlur={((e) => {
                                                if(parseInt(e.target.value) <= 0 || !e.target.value)
                                                    this.props.deletePositionNewOrder(item.index)
                                            })}
                                            />
                                            <button 
                                            className="position_list_count_button"
                                            onClick={(() => {
                                                if(!item.wokData)
                                                    return this.props.changeCountPosition(item.index, item.count, 'plus')
                                                return this.props.changeCountAdditives(item.index, item.count , 'plus',  item.wokData.main.id, 'main')
                                            })}
                                            >
                                                +
                                            </button> 
                                        </span>                                    
                                        {
                                        !item.wokData
                                        ?
                                            !this.props.isAggPrice 
                                            ?
                                            <>
                                                <span className="position_list_position price">  {`${item.isMod ? item.price + item.isMod.price : item.price} ₸`} </span>
                                                <span className="position_list_position totalPrice"> {`${item.count ? item.isMod ? (item.price + item.isMod.price)  * item.count : item.price  * item.count : ''} ₸`} </span>
                                            </>
                                            :
                                            <>
                                                <span className="position_list_position price">  {`${item.isMod ? item.oldPrice + item.isMod.oldPrice : item.oldPrice} ₸`} </span>
                                                <span className="position_list_position totalPrice"> {`${item.count ? item.isMod ? (item.oldPrice + item.isMod.oldPrice)  * item.count : item.oldPrice  * item.count : ''} ₸`} </span>
                                            </>
                                        :
                                            !this.props.isAggPrice 
                                            ?
                                            <>
                                                <span className="position_list_position price">  {`${item.wokData.main.price} ₸`} </span>
                                                <span className="position_list_position totalPrice"> {`${item.count  ? item.wokData.main.price * item.count  : ''} ₸`} </span>
                                            </>
                                            :
                                            <>
                                                <span className="position_list_position price">  {`${item.wokData.main.oldPrice} ₸`} </span>
                                                <span className="position_list_position totalPrice"> {`${item.count  ? item.wokData.main.oldPrice  * item.count  : ''} ₸`} </span>
                                            </>
                                        }
                                    </div>                                
                                    {this.state.openAdditivesWOK === item.index &&
                                    <div className="position_list_position row_name">
                                        <AdditivesWOKNewOrder index={item.index} closeAdditivesWOK={this.closeAdditivesWOK}/>
                                    </div>
                                    }
                                    {item.wokData && item.wokData.meat && Object.values(item.wokData.meat).map((el) => 
                                    <div key={item.index + '_' + el.data.id} className="position_list_row">
                                        <div className="position_list_position container_name"> 
                                            <div className="position_list_position row_name">
                                                <span className="position_list_position name">{'-- ' + el.data.title}</span>                                   
                                            </div>                                
                                        </div>
                                        <span className="position_list_position count"> 
                                            <button 
                                            className="position_list_count_button"
                                            onClick={(() => this.props.changeCountAdditives(item.index, el.count, 'minus',  el.data.id, 'meat'))}
                                            >
                                                -
                                            </button> 
                                            <input
                                            className="new_order_input count"
                                            type="number"
                                            value={el.count}
                                            onChange={((e) => this.props.changeCountAdditives(item.index, el.count, 'handle',  el.data.id, 'meat', e.target.value))}
                                            onBlur={((e) => {
                                                if(parseInt(e.target.value) <= 0 || !e.target.value)
                                                    this.props.deleteAdditivesNewOrder(item.index, el.data.id, 'meat')
                                            })}
                                            />
                                            <button 
                                            className="position_list_count_button"
                                            onClick={(() => this.props.changeCountAdditives(item.index, el.count, 'plus',  el.data.id, 'meat'))}
                                            >
                                                +
                                            </button> 
                                        </span>
                                        {
                                        !this.props.isAggPrice 
                                        ?
                                        <>
                                            <span className="position_list_position price">  {`${el.data.price} ₸`} </span>
                                            <span className="position_list_position totalPrice"> {`${el.count ? el.data.price * el.count : ''} ₸`} </span>
                                        </>
                                        :
                                        <>
                                            <span className="position_list_position price">  {`${el.data.oldPrice} ₸`} </span>
                                            <span className="position_list_position totalPrice"> {`${el.count ? el.data.oldPrice  * el.count : ''} ₸`} </span>
                                        </>
                                        }
                                    </div>
                                    )}     
                                    {item.wokData && item.wokData.topping && Object.values(item.wokData.topping).map((el) => 
                                    <div key={item.index + '_' + el.data.id} className="position_list_row">
                                        <div className="position_list_position container_name"> 
                                            <div className="position_list_position row_name">
                                                <span className="position_list_position name">{'-- ' + el.data.title}</span>                                   
                                            </div>                                
                                        </div>
                                        <span className="position_list_position count"> 
                                            <button 
                                            className="position_list_count_button"
                                            onClick={(() => this.props.changeCountAdditives(item.index, el.count, 'minus',  el.data.id, 'topping'))}
                                            >
                                                -
                                            </button> 
                                            <input
                                            className="new_order_input count"
                                            type="number"
                                            value={el.count}
                                            onChange={((e) => this.props.changeCountAdditives(item.index, el.count, 'handle',  el.data.id, 'topping', e.target.value))}
                                            onBlur={((e) => {
                                                if(parseInt(e.target.value) <= 0 || !e.target.value)
                                                    this.props.deleteAdditivesNewOrder(item.index, el.data.id, 'topping')
                                            })}
                                            />
                                            <button 
                                            className="position_list_count_button"
                                            onClick={(() => this.props.changeCountAdditives(item.index, el.count, 'plus',  el.data.id, 'topping'))}
                                            >
                                                +
                                            </button> 
                                        </span>
                                        {
                                        !this.props.isAggPrice 
                                        ?
                                        <>
                                            <span className="position_list_position price">  {`${el.data.price} ₸`} </span>
                                            <span className="position_list_position totalPrice"> {`${el.count ? el.data.price * el.count : ''} ₸`} </span>
                                        </>
                                        :
                                        <>
                                            <span className="position_list_position price">  {`${el.data.oldPrice} ₸`} </span>
                                            <span className="position_list_position totalPrice"> {`${el.count ? el.data.oldPrice  * el.count : ''} ₸`} </span>
                                        </>
                                        }
                                    </div>
                                    )}         
                                    {item.wokData && item.wokData.souce && Object.values(item.wokData.souce).map((el) => 
                                    <div key={item.index + '_' + el.data.id} className="position_list_row">
                                        <div className="position_list_position container_name"> 
                                            <div className="position_list_position row_name">
                                                <span className="position_list_position name">{'-- ' + el.data.title}</span>                                   
                                            </div>                                
                                        </div>
                                        <span className="position_list_position count"> 
                                            <button 
                                            className="position_list_count_button"
                                            onClick={(() => this.props.changeCountAdditives(item.index, el.count, 'minus',  el.data.id, 'souce'))}
                                            >
                                                -
                                            </button> 
                                            <input
                                            className="new_order_input count"
                                            type="number"
                                            value={el.count}
                                            onChange={((e) => this.props.changeCountAdditives(item.index, el.count, 'handle',  el.data.id, 'souce', e.target.value))}
                                            onBlur={((e) => {
                                                if(parseInt(e.target.value) <= 0 || !e.target.value)
                                                    this.props.deleteAdditivesNewOrder(item.index, el.data.id, 'souce')
                                            })}
                                            />
                                            <button 
                                            className="position_list_count_button"
                                            onClick={(() => this.props.changeCountAdditives(item.index, el.count, 'plus',  el.data.id, 'souce'))}
                                            >
                                                +
                                            </button> 
                                        </span>
                                        {
                                        !this.props.isAggPrice 
                                        ?
                                        <>
                                            <span className="position_list_position price">  {`${el.data.price} ₸`} </span>
                                            <span className="position_list_position totalPrice"> {`${el.count ? el.data.price * el.count : ''} ₸`} </span>
                                        </>
                                        :
                                        <>
                                            <span className="position_list_position price">  {`${el.data.oldPrice} ₸`} </span>
                                            <span className="position_list_position totalPrice"> {`${el.count ? el.data.oldPrice  * el.count : ''} ₸`} </span>
                                        </>
                                        }
                                    </div>
                                    )}   
                                </div>
                                {this.state.openDescription === item.index &&
                                <div className="position_list_row" style={{paddingLeft: '0px'}}>   
                                    <div className="order_position_footer_dropdown_container description">
                                        <div className="order_position_footer_dropdown description" onMouseDown={((e) => e.preventDefault())}>
                                            {"Состав: " + item.description.split('Энергетическая')[0]}
                                        </div>
                                    </div>
                                </div>
                                }     
                            </>
                        )
                    }
                    {parseInt(this.props.unit) > 0 && parseInt(this.props.goodPrice) !== 0 && 
                    <div className="position_list_column">
                        <div className="position_list_row">
                            <div className="position_list_position container_name"> 
                                <div className="position_list_position row_name">
                                    <span className="position_list_position name">Приборы</span>                                   
                                </div>                                
                            </div>
                            <span className="position_list_position count"> {this.props.unit} </span>
                            <span className="position_list_position price"> 0 ₸ </span>
                            <span className="position_list_position totalPrice"> 0 ₸ </span>     
                        </div>          
                    </div>       
                    }

                    {this.props.deliveryPrice > 0 && parseInt(this.props.goodPrice) !== 0 && 
                    <div className="position_list_column">
                        <div className="position_list_row">
                            <div className="position_list_position container_name"> 
                                <div className="position_list_position row_name">
                                    <span className="position_list_position name">Доставка</span>                                   
                                </div>                                
                            </div>
                            <span className="position_list_position count"> 1 </span>
                            <span className="position_list_position price"> {`${this.props.deliveryPrice} ₸`} </span>
                            <span className="position_list_position totalPrice"> {`${this.props.deliveryPrice} ₸`} </span>     
                        </div>       
                    </div>         
                    }
                    
                    {parseInt(this.props.goodPrice) !== 0 && 
                        <div className="position_list_row totalPrice">
                            <span>Всего</span>
                            <span>{this.props.goodPrice + ' ₸'}</span>       
                        </div>                
                    }       
                </div>      
                <div className="order_position_list_footer">
                    {(parseInt(this.props.totalPrice) !== 0 || this.props.salePrice > 0) && 
                        <div className="position_list_row totalPrice" style={{paddingTop: '0px'}}>
                            <span>{this.props.salePrice > 0 && 'Скидка ' + this.props.salePrice + ' ₸'} </span>
                            <span>{'К оплате '} <span style={{fontWeight: '600'}}>{this.props.totalPrice}</span> {' ₸'}</span>       
                        </div>                
                    }      
                    {(parseInt(this.props.totalPrice) !== 0 || this.props.salePrice > 0) &&
                        <div className="order_position_list_footer_buttons_div">
                            <div className="order_position_list_footer_acide_buttons">
                                {!this.state.openInputSale ?
                                <>
                                    {
                                    <div>                                        
                                        {this.state.openSaleDropDown && 
                                        <div className="order_position_footer_dropdown_container">
                                            <div className="order_position_footer_dropdown">
                                                    <button
                                                    className={`order_position_footer_dropdown_button ${!this.props.sale && parseFloat(this.props.salePrice) === 0 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => this.props.changeSale('percent', null))}
                                                    >
                                                        Без скидки
                                                    </button>       
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.sale === 0.05 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => this.props.changeSale('percent', 0.05))}
                                                    >
                                                        -5%
                                                    </button>                                    
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.sale === 0.1 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => this.props.changeSale('percent', 0.1))}
                                                    >
                                                        -10%
                                                    </button>                                    
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.sale === 0.15 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => this.props.changeSale('percent', 0.15))}
                                                    >
                                                        -15%
                                                    </button>     
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.sale === 0.2 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => this.props.changeSale('percent', 0.2))}
                                                    >
                                                        -20%
                                                    </button>                                    
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.sale === 0.25 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => this.props.changeSale('percent', 0.25))}
                                                    >
                                                        -25%
                                                    </button>                                    
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.sale === 0.3 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => this.props.changeSale('percent', 0.3))}
                                                    >
                                                        -30%
                                                    </button>        
                                                    <button 
                                                    className={`order_position_footer_dropdown_button ${this.props.sale === null && this.props.salePrice > 0 ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => this.setState({openInputSale: true, openSaleDropDown: false}))}
                                                    >
                                                        Суммой
                                                    </button>                                                        
                                            </div>
                                        </div>
                                        }
                                        <button 
                                        className={`order_position_list_footer_button ${(this.props.sale || this.props.salePrice > 0) ? 'actived' : ''}`}
                                        onClick={(() => this.setState({openSaleDropDown : true}))}
                                        onBlur={(() => this.setState({openSaleDropDown : false}))}
                                        >
                                            {this.props.sale && (this.props.sale * 100)}%
                                        </button>
                                    </div>}
                                    <div>
                                        {this.state.openUnitDropDown &&
                                        <div className="order_position_footer_dropdown_container">
                                            <div className="order_position_footer_dropdown">                                
                                            {
                                                Array.from({length: 21}).map((_, idx) => 
                                                    <button 
                                                    key={idx}
                                                    className={`order_position_footer_dropdown_button ${(this.props.unit === idx || (this.props.unit === null && idx === 0 )) ? 'actived' : ''}`}
                                                    onMouseDown={((e) => e.preventDefault())}
                                                    onClick={(() => this.props.changeUnit(idx))}
                                                    >
                                                        {idx > 0 ? idx : 'Без приборов'}    
                                                    </button>
                                                )
                                            }
                                            </div>
                                        </div>
                                        }
                                        <button 
                                        className="order_position_list_footer_button"
                                        onClick={(() => this.setState({openUnitDropDown: true}))}
                                        onBlur={(() => this.setState({openUnitDropDown: false}))}
                                        >
                                            <i className="flaticon-more-v2"/>
                                        </button>                                                                                
                                    </div>
                                </>
                                :
                                <>
                                    <div style={{ display: 'flex', gap: '5px', width: '50%'}}>
                                        <input 
                                        className="new_order_input"
                                        style={{ width: '100%'}}
                                        type="number"
                                        placeholder="Скидка"
                                        value={this.state.inputSale}
                                        onChange={this.handleChangeInputSale.bind(this)}
                                        ref={(input) => {
                                            if(input)                            
                                              input.focus();                                            
                                        }}
                                        onBlur={(() => {
                                                this.setState({inputSale: '', openInputSale: false})
                                        })}
                                        />
                                        <button 
                                        className="order_position_list_footer_button"
                                        style={{ padding: '0px', height: '1.6rem', minWidth: '2rem'}}
                                        onMouseDown={((e) => e.preventDefault())}
                                        onClick={(() => {
                                            if(this.state.inputSale)       
                                                this.props.changeSale('handle', this.state.inputSale);    
                                            this.setState({inputSale: '', openInputSale: false});
                                        })}
                                        >
                                            <i className="flaticon2-check-mark"/>
                                        </button>
                                    </div>
                                </>
                                }
                                {this.props.zones && this.props.deliveryMethod === 'delivery' &&                           
                                    <div>
                                        {this.state.openDeliveryPriced &&
                                        <div className="order_position_footer_dropdown_container">
                                            <div className="order_position_footer_dropdown">           
                                            <button 
                                            className={`order_position_footer_dropdown_button ${this.props.deliveryPrice === 0 ? 'actived' : ''}`}
                                            onMouseDown={((e) => e.preventDefault())}
                                            onClick={(() => this.props.addDeliveryPriced(0, null))}
                                            >
                                                Бесплатная доставка    
                                            </button>                     
                                            {
                                                this.props.zones.map((item) => 
                                                <button 
                                                key={item.deliveryCode}
                                                className={`order_position_footer_dropdown_button ${this.props.deliveryPrice === item.deliveryPrice ? 'actived' : ''}`}
                                                onMouseDown={((e) => e.preventDefault())}
                                                onClick={(() => this.props.addDeliveryPriced(item.deliveryPrice, item.deliveryCode))}
                                                >
                                                    {item.deliveryPrice + ' ₸'}    
                                                </button>
                                                )
                                            }
                                            </div>
                                        </div>
                                        }
                                        <button
                                        className={`order_position_list_footer_button ${(this.props.deliveryPrice > 0) ? 'actived' : ''}`}
                                        style={{ padding: '0.15rem 0.75rem' }}
                                        onClick={(() => this.setState({openDeliveryPriced: true}))}
                                        onBlur={(() => this.setState({openDeliveryPriced: false}))}
                                        >
                                            Доставка
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className="order_position_list_footer_acide_buttons">
                                <button 
                                className="order_position_list_footer_button"
                                onClick={(() => {this.props.clearNewOrder()})}
                                >
                                    <i className="flaticon2-trash"/>
                                </button>
                                <SaveNewOrderButton/>
                            </div>
                        </div>
                    }
                </div>   
            </>            
        );
    }
}
const mapStateToProps = state => ({
    addedgoods: state.neworders.goods,
    goodPrice: state.neworders.goodPrice,
    totalPrice: state.neworders.totalPrice,
    salePrice: state.neworders.salePrice,
    unit: state.neworders.unit,
    sale: state.neworders.sale,
    isAggPrice: state.neworders.isAggPrice,
    zones: state.neworders.zones,
    deliveryPrice: state.neworders.deliveryPrice,
    deliveryMethod: state.neworders.deliveryMethod,
    settings: state.settings.init.categories
});
const mapDispatchToProps = {
    changeCountPosition: (index, count, type, value) => changeCountPosition(index, count, type, value),
    deletePositionNewOrder: (id) => deletePositionNewOrder(id),
    changeUnit: (value) => changeUnit(value),
    changeSale: (type, value) => changeSale(type, value),
    clearNewOrder: () => clearNewOrder(),
    addPositionNewOrder: (good, settings) => addPositionNewOrder(good, settings),
    changeModificationOrderPositionList: (index, mod) => changeModificationOrderPositionList(index, mod),
    changeCountAdditives: (index, count, type, idAdditives, subtype, value) => changeCountAdditives(index, count, type, idAdditives, subtype, value),
    deleteAdditivesNewOrder: (index, id, subtype) => deleteAdditivesNewOrder(index, id, subtype),
    addDeliveryPriced: (price, article) => addDeliveryPriced(price, article)
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderPositionList);