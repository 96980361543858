import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchGood, setDetailGood } from '../../action';
import Content from '../../components/layout/Content';
import BackButton from '../../components/utils/BackButton';
import ErrorContainer from '../../components/utils/ErrorContainer';
import Loader from '../../components/utils/Loader';
import { fromStore } from '../../selectors';
import { DetailGood } from '../../containers';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

function DetailGoodPage({ match }) {
    const dispatch = useDispatch();
    const detail = useSelector(fromStore.detailGoodSelector);
    const loading = useSelector(fromStore.loaderDetailGoodSelector);
    const id = match.params.id;

    useEffect(() => {
        dispatch(fetchGood(id));
        return () => dispatch(setDetailGood(null));
    }, [dispatch, id]);

    const onRefresh = () => {
        dispatch(fetchGood(id));
    };

    if (loading || !detail) {
        return (
            <div style={styles.loading}><Loader /></div>
        );
    }

    const renderSubHeader = () => {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <BackButton />
                    <h3 className="kt-subheader__title">{detail.name}</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {detail.category.title}
                        </span>
                    </div>
                </div>
                <div className="kt-subheader__toolbar">
                    <button onClick={onRefresh} className="btn btn-secondary btn-icon"><i className="la la-refresh"></i></button>
                </div>
            </div>
            </div>
        );
    };


    return (
        <Content>
            { renderSubHeader() }
            <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                <ErrorContainer field="detailGood" hasCloseButton={true}/>
                <DetailGood />
            </div>
        </Content>
    );
}

export default withRouter(DetailGoodPage);