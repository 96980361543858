import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchOrders, setOrdersPaging, deleteOrder, setOrdersFilters } from '../../action/orders';
import { fetchCities, fetchFilials } from '../../action/handbook';
import Loader from '../utils/Loader';
import Portlet from '../utils/Portlet';
import ReactPaginate from 'react-paginate';
import OrdersTable from './OrdersTable';
import swal from 'sweetalert';
import ErrorContainer from '../utils/ErrorContainer';
import Select from 'react-select';
import Input from '../utils/Input';
import DateInput from '../utils/DateInput';
import Switch from '../utils/Switch';
import moment from 'moment';
import '../../styles/order_table.css'
import { fetchSettings } from '../../action/app';
import request from '../../action/utils';
import { roleId, urls } from '../../constants/app';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class OrdersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pizza: [],
            loadingPizza: true
        };
        this.fetchPizza = this.fetchPizza.bind(this); 
    }

    fetchPizza = async () => { //также временный костыль из за различий json заказов в модуле принятия и сайте/приложении, когда подправлю надо вырезать
        if(!this.props.settings) {
            this.props.fetchSettings(); 
        }

        if(this.state.pizza.length <= 0 && this.props.goods.length <= 0 && this.props.settings)  
        {
            this.setState({loadingPizza: true})
            const params = { withoutFilterOnTime: true };
            try {
                const pizza = await request({ method: 'get', url: urls.goods, params });
                this.setState({pizza: pizza.filter((el) => el.category.id === this.props.settings.pizza)})
                this.setState({loadingPizza: false})
            } catch(error) {
                this.setState({loadingPizza: false})
            }
        }

        if(this.props.goods.length > 0 && this.props.settings)
        {
            this.setState({loadingPizza: true})
            this.setState({pizza: this.props.goods.filter((el) => el.category.id === this.props.settings.pizza)})
            this.setState({loadingPizza: false})
        }
    }

    componentDidMount() {
        this.props.fetchOrders();
        this.props.fetchCities();
        this.props.fetchFilials(null);
        this.props.fetchSettings();              
        this.fetchPizza();
    }

    state = {
        currentPage: 0
    }

    componentWillUnmount() {
        const { paging } = this.props;
        this.props.setOrdersPaging({ ...paging, start: 0 });
    }

    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setOrdersPaging(updatedPaging);
        this.props.fetchOrders();
    }

    handlePageClick = data => {
        const { paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setOrdersPaging(updatedPaging);
            this.props.fetchOrders();
        });
    };

    renderPagination() {
        const { count, paging } = this.props;
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded order_table">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={8}
                    pageRangeDisplayed={5}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                />

                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select onChange={this.setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
    
        );
    }

    setOrdersFilters = (field) => (data) => {
        const { filters, paging } = this.props;
        const updated = {
            ...filters,
            [field]: data
        }
        if (field === 'city') {
            updated.point = null;
            this.props.fetchFilials(data.value)
        }
        this.props.setOrdersFilters(updated);
        this.props.setOrdersPaging({ ...paging, start: 0 });
        this.props.fetchOrders();
    }

    onChangeInput = (field) => (e) => {
        const value = e.target.value;

        const { filters } = this.props;
        const updated = {
            ...filters,
            [field]: value
        }
        this.props.setOrdersFilters(updated);
    }

    onChangeDateInput = (field) => (date) => {
        const { filters } = this.props;

        const updated = {
            ...filters,
            [field]: date,
        };

        if (field === 'createdStartDate') {
            updated.createdEndDate = moment(date).add({ days: 30 }).toDate()
        }

        this.props.setOrdersFilters(updated);
    }

    onSearch = () => {
        const { paging } = this.props;
        this.props.setOrdersPaging({ ...paging, start: 0 });
        this.setState({ currentPage: 0 });
        this.props.fetchOrders();
    }

    resetFilter = () => {
        this.props.setOrdersFilters({ city: null, point: null, status: null, createdEndDate: null, createdStartDate: null });
        this.setState({ currentPage: 0 });
        this.props.fetchOrders();
    }

    renderAdress(item) {
        return `${item.city.name}, ${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Заказы</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {this.props.count}
                        </span>
                    </div>
                    {this.props.role === roleId.admin && 
                    <>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <h3 className="kt-subheader__title"> Средний чек </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                {(this.props.statistics.result && this.props.count > 0) ? parseFloat(this.props.statistics.result.sum / this.props.count).toFixed(2) : 'Ошибка'}
                            </span>
                        </div>
                        
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <h3 className="kt-subheader__title"> Выручка </h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        <div className="kt-subheader__group" id="kt_subheader_search">
                            <span className="kt-subheader__desc" id="kt_subheader_total">
                                { this.props.statistics.result ? this.props.statistics.result.sum : 'Ошибка'}
                            </span>
                        </div>
                    </>
                    }
                </div>
            </div>
            </div>
        );
    }

    onDelete = (id) => {
        swal({
            title: `Удаление заказа`,
            text: `Вы действительно хотите удалить заказ #${id}?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                this.props.deleteOrder(id).then(result => {
                    if (result) {
                        this.props.fetchOrders();
                    }
                })
            }
        });
    }

    onRefresh = () => {
        this.props.fetchOrders(this.props.isArchive);
        this.props.fetchFilials(null);
        if(!this.props.settings)
            this.props.fetchSettings();
        if(!this.state.pizza)
            this.fetchPizza();
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.onSearch(); 
        }
    }

    render() {
        if (this.props.loading || this.props.loadingSettings || this.state.loadingPizza) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }
        
        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                    <div className="kt-portlet__body">
                        <div className="kt-form kt-form--label-right kt-margin-b-10">
                            <div className="filterBlocks" onKeyDown={this.onKeyDown}>
                                        <div className="dateBlock">
                                            <DateInput
                                                placeholderText="Дата от"
                                                selected={this.props.filters.createdStartDate}
                                                onChange={this.onChangeDateInput('createdStartDate')}
                                                maxDate={moment().toDate()}
                                            />
                                            <div>-</div>
                                            <DateInput
                                                placeholderText="Дата до"
                                                selected={this.props.filters.createdEndDate}
                                                onChange={this.onChangeDateInput('createdEndDate')}
                                                maxDate={moment(this.props.filters.createdStartDate).add({ days: 30 }).toDate()}
                                                required={this.props.filters.createdStartDate}
                                            />
                                        </div>
                                        <div>
                                            <Input placeholder="Телефон" onChange={this.onChangeInput('phone')} disableGroup={true} value={this.props.filters.phone}/>
                                        </div>
                                        <div style={{ width: 250 }}>
                                            <Select
                                                isLoading={this.props.loadingCity}
                                                isDisabled={this.props.loadingCity}
                                                value={this.props.filters.city}
                                                closeMenuOnSelect={true}
                                                onChange={this.setOrdersFilters('city')}
                                                options={this.props.cities.map(item => ({
                                                    value: item.id,
                                                    label: item.name
                                                }))}
                                                placeholder="Город"
                                                noOptionsMessage={() => 'Нет городов'}
                                            />
                                        </div>
                                        <div style={{ width: 250 }}>
                                            <Select
                                                isLoading={this.props.loadingFilials}
                                                isDisabled={this.props.loadingFilials || this.props.filters.zone}
                                                value={this.props.filters.point}
                                                closeMenuOnSelect={true}
                                                onChange={this.setOrdersFilters('point')}
                                                options={this.props.filials.map(item => ({
                                                    value: item.id,
                                                    label: this.renderAdress(item)
                                                }))}
                                                placeholder="Пункт самовывоза"
                                                noOptionsMessage={() => 'Нет городов'}
                                            />
                                        </div>
                                        <div style={{ width: 250 }}>
                                            <Select
                                                value={this.props.filters.status}
                                                closeMenuOnSelect={true}
                                                onChange={this.setOrdersFilters('status')}
                                                options={[{ value: 'new', label: 'Новый' }, { value: 'cancel', label: 'Отменен' }, { value: 'done', label: 'Выполнен' }].map(item => ({
                                                    value: item.value,
                                                    label: item.label
                                                }))}
                                                placeholder="Статус"
                                                noOptionsMessage={() => 'Нет статусов'}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <button onClick={this.onRefresh} className="btn btn-secondary btn-icon"><i className="la la-refresh"></i></button>
                                        </div>
                                        <div style={{ padding: '0 20px', display: 'flex', alignItems: 'center' }}>
                                                <div style={{ marginRight: '10px' }}>Только прямые заказы</div>
                                                <Switch nomargin={true} checked={this.props.filters.zone} onChange={this.setOrdersFilters('zone')}/>
                                        </div>
                                        <div>
                                            <button style={{ marginRight: 5 }} onClick={this.onSearch} className="btn btn-primary">Найти</button>
                                            <button onClick={this.resetFilter} className="btn btn-danger">Сбросить</button>
                                        </div>
                                      
                            </div>
                        </div>
                    </div>
                    <Portlet fit={true}>
                        <ErrorContainer field="orders" style={{ margin: 15, backgroundColor: '#00000000' }} hasCloseButton={true}/>
                        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" style={{ marginBottom: '0px'}}>
                            <OrdersTable role={this.props.role} onDelete={this.onDelete} items={this.props.orders} filials={this.props.filials} filters={this.props.filters}  pizza={this.state.pizza}/>
                            {
                                this.props.orders.length > 0 && this.renderPagination()
                            }
                        </div>
                    </Portlet>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    statistics: state.statistics.ordersOfHead,
    orders: state.orders.list,
    filters: state.orders.filters,
    loading: state.loaders.orders,
    count: state.orders.count,
    paging: state.orders.paging,
    cities: state.handbook.cities,
    filials: state.handbook.filials,
    role: state.user.info.role.id,
    settings: state.settings.init.categories,
    loadingOrders: state.loaders.orders || state.loaders.ordersOfHead,
    loadingFilials: state.loaders.filials,
    loadingCity: state.loaders.cities,
    loadingGoods: state.loaders.goods,
    loadingSettings: state.loaders.settings,
    goods: state.good.list,
});

const mapDispatchToProps = {
    fetchOrders: () => fetchOrders(),
    setOrdersPaging: (paging) => setOrdersPaging(paging),
    deleteOrder: (id) => deleteOrder(id),
    fetchCities: () => fetchCities(true),
    fetchFilials: (city) => fetchFilials(city, true),
    setOrdersFilters: (filters) => setOrdersFilters(filters),
    fetchSettings: () => fetchSettings(),
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);