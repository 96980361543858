import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchRatings, setRatingsPaging, setRatingsFilters, fetchMiddleScoreRatings } from '../action/ratings';
import Loader from '../components/utils/Loader';
import Portlet from '../components/utils/Portlet';
import ReactPaginate from 'react-paginate';
import RatingsList from './RatingsList';

import ErrorContainer from '../components/utils/ErrorContainer';
import Select from 'react-select';
import Content from '../components/layout/Content';
import DateInput from '../components/utils/DateInput';
import moment from 'moment';


const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class Ratings extends Component {
    componentDidMount() {
        this.props.fetchRatings();
        this.props.fetchMiddleScoreRatings();
    }

    state = {
        currentPage: 0
    }

    componentWillUnmount() {
        const { paging } = this.props;
        this.props.setRatingsPaging({ ...paging, start: 0 });
    }

    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setRatingsPaging(updatedPaging);
        this.props.fetchRatings();
    }

    handlePageClick = data => {
        const { paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setRatingsPaging(updatedPaging);
            this.props.fetchRatings();
        });
    };

    renderPagination() {
        const { count, paging } = this.props;
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={8}
                    pageRangeDisplayed={5}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                />

                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select onChange={this.setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
    
        );
    }

    setRatingsFilters = (field) => (data) => {
        const { filters } = this.props;
        const updated = {
            ...filters,
            [field]: data
        }
        this.props.setRatingsFilters(updated);
        this.props.fetchRatings();
    }

    resetFilter = () => {
        this.props.setRatingsFilters({ city: null, point: null, status: null });
        this.setState({ currentPage: 0 });
        this.props.fetchRatings();
        this.props.fetchMiddleScoreRatings();
    }

    onChangeDateInput = (field) => (date) => {
        const { filters } = this.props;

        const updated = {
            ...filters,
            [field]: date,
        };

        if (field === 'createdStartDate') {
            updated.createdEndDate = moment(date).add({ days: 30 }).toDate()
        }

        this.props.setRatingsFilters(updated);
    }

    onSearch = () => {
        const { paging } = this.props;
        this.props.setRatingsPaging({ ...paging, start: 0 });
        this.setState({ currentPage: 0 });
        this.props.fetchRatings();
        this.props.fetchMiddleScoreRatings();
    }


    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Оценки</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {this.props.count}
                        </span>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                        <div className="dateBlock">
                            <DateInput
                                placeholderText="Дата от"
                                selected={this.props.filters.createdStartDate}
                                onChange={this.onChangeDateInput('createdStartDate')}
                                maxDate={moment().toDate()}
                            />
                            <div>-</div>
                            <DateInput
                                placeholderText="Дата до"
                                selected={this.props.filters.createdEndDate}
                                onChange={this.onChangeDateInput('createdEndDate')}
                                maxDate={moment(this.props.filters.createdStartDate).add({ days: 30 }).toDate()}
                                required={this.props.filters.createdStartDate}
                            />
                        </div>
                    </div>
                    <div className="kt-margin-l-10 kt-input-icon kt-input-icon--right kt-subheader__search">
                        <Select
                            value={this.props.filters.kitchen}
                            closeMenuOnSelect={true}
                            onChange={this.setRatingsFilters('kitchen')}
                            options={[1, 2, 3, 4, 5].map(item => ({
                                value: item,
                                label: `Оценка - ${item}`
                            }))}
                            placeholder="Кухня"
                        />
                    </div>
                    <div className="kt-margin-l-10 kt-input-icon kt-input-icon--right kt-subheader__search">
                        <Select
                            value={this.props.filters.delivery}
                            closeMenuOnSelect={true}
                            onChange={this.setRatingsFilters('delivery')}
                            options={[1, 2, 3, 4, 5].map(item => ({
                                value: item,
                                label: `Оценка - ${item}`
                            }))}
                            placeholder="Доставка"
                        />
                    </div>
                    <div className="kt-margin-l-10 kt-input-icon kt-input-icon--right kt-subheader__search">
                        <Select
                            value={this.props.filters.service}
                            closeMenuOnSelect={true}
                            onChange={this.setRatingsFilters('service')}
                            options={[1, 2, 3, 4, 5].map(item => ({
                                value: item,
                                label: `Оценка - ${item}`
                            }))}
                            placeholder="Сервис"
                        />
                    </div>
                    <div className="kt-margin-l-10 kt-input-icon kt-input-icon--right kt-subheader__search">
                        <button style={{ marginRight: 5 }} onClick={this.onSearch} className="btn btn-primary">Найти</button>
                        <button onClick={this.resetFilter} className="btn btn-danger">Сбросить</button>
                    </div>
                </div>
            </div>
            </div>
        );
    }

    render() {
        if (this.props.loading) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }
        
        return (
            <Content>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <Portlet fit={true}>
                        <ErrorContainer field="ratings" style={{ margin: 15 }} hasCloseButton={true}/>
                        <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                            <RatingsList middle={this.props.middle} items={this.props.ratings}/>
                            {
                                this.props.ratings.length > 0 && this.renderPagination()
                            }
                        </div>
                    </Portlet>
                </div>
            </Content>
        );
    }
}

const mapStateToProps = state => ({
    ratings: state.ratings.list,
    filters: state.ratings.filters,
    loading: state.loaders.ratings,
    count: state.ratings.count,
    paging: state.ratings.paging,
    middle: state.ratings.middle
});

const mapDispatchToProps = {
    fetchRatings: () => fetchRatings(),
    setRatingsPaging: (paging) => setRatingsPaging(paging),
    setRatingsFilters: (filters) => setRatingsFilters(filters),
    fetchMiddleScoreRatings: () => fetchMiddleScoreRatings()
}

export default connect(mapStateToProps, mapDispatchToProps)(Ratings);